import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../utility';

const initialState = {
    customercleaner: null, 
    error: false
}

export const get_cc_start = (state) => {
    return updateObject(state);
};

export const get_cc_success = (state, action) => {
    return updateObject(state, {
        customercleaner: action.customercleaner
    });
};

export const get_cc_fail = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};


export const save_cc_start = (state) => {
    return updateObject(state);
};

export const save_cc_success = (state, action) => {
    return updateObject(state, {
        customercleaner: action.customercleaner
    });
};

export const save_cc_fail = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};
export const delete_cc_start = (state) => {
    return updateObject(state);
}
export const delete_cc_success = (state) => {
    return updateObject(state);
}
export const delete_cc_fail = (state, action) => {
    return updateObject(state, {
        error: action.error
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CUS_CLEANER_START: return get_cc_start(state, action); 
        case actionTypes.GET_CUS_CLEANER_SUCCESS: return get_cc_success(state, action);
        case actionTypes.GET_CUS_CLEANER_FAIL: return get_cc_fail(state, action);

        case actionTypes.SAVE_CUS_CLEANER_START: return save_cc_start(state, action); 
        case actionTypes.SAVE_CUS_CLEANER_SUCCESS: return save_cc_success(state, action);
        case actionTypes.SAVE_CUS_CLEANER_FAIL: return save_cc_fail(state, action);

        case actionTypes.DELETE_CUS_CLEANER_START: return delete_cc_start(state);
        case actionTypes.DELETE_CUS_CLEANER_SUCCESS: return delete_cc_success(state);
        case actionTypes.DELETE_CUS_CLEANER_FAIL: return delete_cc_fail(state, action);
        default: return state;
    }
};

export default reducer; 