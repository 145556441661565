import React, {Component} from 'react';
import * as classess from './schedulecontrol.module.css';
import {connect} from 'react-redux';
import * as cc_action from '../../../store/actions/cuscleaner_action';
import * as customer_action from '../../../store/actions/cus_action';
// import cleaner from '../../Cleaner/cleaner';

class ScheduleControl extends Component {
    state= {
        assignedcleaners: [],
        unassignedcleaners: [],
        workingtime: {
            hour: "7",
            minute: "00"
        }, 
        isworkingtimesaved: true
    }

    componentDidMount = () => {
        if (this.props.customercleaner) {
            let assignedcleaners = this.getAssignedCleanersByCustomerID(this.props.customerkey);
            this.setState({ assignedcleaners: assignedcleaners })
        }
    }

    getAssignedCleanersByCustomerID = (customerid) => {
        const cusclean = this.props.customercleaner;
        var cuscleankeys = Object.keys(cusclean).filter(key => {
            return (cusclean[key].customerid === customerid)
                        &&(this.comparedateHandler(cusclean[key].workingdate,this.props.workingdate))
        });
        let assigned = [];
        let found = [...cuscleankeys];
        // eslint-disable-next-line
        found.map(item => {
            assigned.push(cusclean[item].cleanerid)
        })
        return assigned; 
    }
    comparedateHandler = (date1, date2) => {
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        return (d1.getMonth()===d2.getMonth())&&(d1.getFullYear()===d2.getFullYear())&&(d1.getDate()===d2.getDate())
    }
    
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const cleanerid = target.name;
        
        let assignedcleaners = [...this.state.assignedcleaners];
        let unassignedcleaners = [...this.state.unassignedcleaners];
        
        if(value){ // If checkbox is checked, we add a new customer-cleaner relationship to the array
            assignedcleaners.push(cleanerid);
            let pos = unassignedcleaners.indexOf(cleanerid);
            if(pos>=0) unassignedcleaners.splice(pos,1);
        }
        if(!value){
            let pos = assignedcleaners.indexOf(cleanerid);
            if(pos>=0) assignedcleaners.splice(pos,1);
            unassignedcleaners.push(cleanerid);
        }
        this.setState({assignedcleaners:assignedcleaners,unassignedcleaners:unassignedcleaners}); 
    }

    handleSaveAssignment = (event) => {
        event.preventDefault();
        var result = window.confirm("Save this?")
        if (result) {
            //Handle create new or update items that are checked or assigned in checkboxes 
            // eslint-disable-next-line
            this.state.assignedcleaners.map(id => {
                //Need to check Create New or Update
                var data = {
                    customerid: this.props.customerkey,
                    cleanerid: id,
                    assignmentdate: new Date(),
                    workingdate: this.props.workingdate
                }
                const cuscleanid = this.checkAssignmentData(this.props.customerkey, id, this.props.workingdate);
                cuscleanid ? this.props.onUpdateCustomerCleaner(cuscleanid, data, this.props.token) : this.props.onCreateCustomerCleaner(data, this.props.token);
            });
            //Handle un-assigned by uncheck the checkboxes
            // eslint-disable-next-line
            this.state.unassignedcleaners.map(id => {
                //Need to check the existance of the combination (cusid, cleanid and workingdate)
                const cuscleanid = this.checkAssignmentData(this.props.customerkey, id, this.props.workingdate);
                if (cuscleanid)
                    this.props.onDeleteCustomerCleaner(cuscleanid, this.props.token)
            });
        }
        if(result){
            alert("Assignment is saved successfully"); 
        }
    }

    checkAssignmentData = (customerid, cleanerid, workingdate) => {
        const cusclean = this.props.customercleaner;
        if(!cusclean) return null;
        return Object.keys(cusclean).find(key => {
            return (cusclean[key].customerid === customerid)&&(cusclean[key].cleanerid===cleanerid)
                &&(this.comparedateHandler(cusclean[key].workingdate,workingdate))
        })
    }

    assignedCleanerCheckboxesHandler = (cleanerid) => {
        let found = this.state.assignedcleaners.find(item => {
            return this.state.assignedcleaners[item] === cleanerid
        })
        return found; 
    }


    getWorkingTimeHandler = (date) => {
        var newDate = new Date(date);
        return newDate.getHours() + ":" + newDate.getMinutes();
    }

    eventValueChangeHandler = (event) => {
        this.setState({isworkingtimesaved:false});
        let updatedworkingtime = {...this.state.workingtime};
        let updatedvalue = event.target.value; 
        updatedworkingtime[event.target.id] = updatedvalue;
        this.setState({workingtime: updatedworkingtime})
    }

    eventSaveTimeHandler = (event) => {
        event.preventDefault();
        let updatedCustomer = {...this.props.customer};
        let newWorkingTime = new Date(updatedCustomer.startedworkingday);
        newWorkingTime.setHours(this.state.workingtime.hour, this.state.workingtime.minute);
        updatedCustomer["startedworkingday"] = newWorkingTime; 
        //Save customer working time to DB
        this.props.onUpdateCustomer(this.props.customerkey,updatedCustomer, this.props.token);
        this.setState({isworkingtimesaved:true});
    }

    render(){
        let cleanercheckboxes = null;
        if(this.props.cleaners && this.state.assignedcleaners){//Should filter cleaners by working day that they can work. 
            let cleaners = this.props.cleaners;
            cleanercheckboxes = Object.keys(cleaners).map(cleanerkey => (
                //Checkboxes display cleaners 
                <div key={cleanerkey} className={classess.cleanercheckbox}>
                    <label>
                        {cleaners[cleanerkey].name}
                    </label>
                    <input
                        // disabled={!this.props.isAuthenticated}
                        name={cleanerkey}
                        type="checkbox"
                        checked={this.state.assignedcleaners.indexOf(cleanerkey)>=0?true:false}
                        onChange={this.handleInputChange} />
                </div>  
            ))
        }
        return (
            <div key={this.props.customer.id} className={classess.customerinfo}>
                <h5>
                    <b>
                        {this.props.customer.name} - {this.props.customer.address}
                    </b>
                </h5>
                <b>Working Time: {this.getWorkingTimeHandler(this.props.customer.startedworkingday)}</b>
                <br/>
                <select onChange={(event) => this.eventValueChangeHandler(event)} id="hour">
                    <option key="0" value="6">6</option>
                    <option key="1" value="7">7</option>
                    <option key="2" value="8">8</option>
                    <option key="3" value="9">9</option>
                    <option key="4" value="10">10</option>
                    <option key="5" value="11">11</option>
                    <option key="6" value="12">12</option>
                    <option key="7" value="13">13</option>
                    <option key="8" value="14">14</option>
                    <option key="9" value="15">15</option>
                    <option key="10" value="16">16</option>
                    <option key="11" value="17">17</option>
                    <option key="12" value="18">18</option>
                    <option key="13" value="19">19</option>
                    <option key="14" value="20">20</option>
                    <option key="21" value="21">21</option>
                </select>
                <select onChange={(event) => this.eventValueChangeHandler(event)} id="minute">
                    <option key="0" value="00">00</option>
                    <option key="1" value="15">15</option>
                    <option key="2" value="30">30</option>
                </select>
                <button className="button" onClick={(event)=> this.eventSaveTimeHandler(event)}>Save</button>
                <br/>
                {cleanercheckboxes}
                <br/>
                <button className="button" disabled={!this.state.isworkingtimesaved} onClick={this.handleSaveAssignment}>Save Assignment</button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        token: state.auth.token
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onCreateCustomerCleaner: (data, token)=> dispatch(cc_action.createCustomerCleaner(data, token)),
        onDeleteCustomerCleaner: (id, token)=> dispatch(cc_action.deleteCustomerCleaner(id, token)), 
        onUpdateCustomerCleaner: (id, data, token) => dispatch(cc_action.updateCustomerCleaner(id,data, token)),
        onUpdateCustomer: (id, data, token)=> dispatch(customer_action.updateCustomer(id, data, token)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleControl); 