import React, {Component} from 'react';
import * as classes from './labels.module.css';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import * as post_actions from '../../store/actions/post_actions';

class Labels extends Component {
    setLabelToState = (label) => {
        this.props.onGetPostByLabel(label);
    }
    render(){
        let list = null;
        const labels = this.props.labels;
        if (labels) {
            list = (labels.map(label => (
                <NavLink to={'/labels/' + label} onClick={(e)=> this.setLabelToState(label)}
                    key={label}>{label}</NavLink>
            )))
        }
        return (
            <div className={classes.tag}>
                <b>Tags</b>
                {list}
            </div>
        );
    }
} 
const mapStateToProps = state => {
    return {
        labels: state.post.labels, 
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGetPostByLabel: (label) => dispatch(post_actions.getPostsByLabel(label)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Labels)