import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    posts: null,
    post: null,
    postid: null, 
    labels: null,
    error: null, 
    action_result: null, 
    loading: null
}
//----------------------------------------------
export const getPostsStart = (state, action) => {
    return updateObject(state, {
        loading: action.loading
    });
}
export const getPostsSuccess = (state, action) => {
    return updateObject(state, {
        posts: action.posts,
        loading: action.loading,
        error: null
    })
}
export const getPostsFail = (state, action) => {
    return updateObject(state, {
        posts: null,
        loading: action.loading, 
        error: action.error
    })
}
//----------------------------------------------
export const getPostStart = (state, action) => {
    return updateObject(state); 
}
export const getPostSuccess = (state, action) => {
    return updateObject(state, {
        post: action.post, 
        postid: action.postid,
        error: null, 
        action_result: action.action_result
    })
}
export const getPostFail = (state, action) => {
    return updateObject(state, {
        error: action.error, 
        action_result: action.action_result
    })
}
//----------------------------------------------
export const deleteStart = (state, action) => {
    return updateObject(state);
}
export const deleteFail = (state, action) => {
    return updateObject(state, {
        error: action.error, 
        action_result: action.action_result
    })
}
export const deleteSuccess = (state, action) => {
    return updateObject(state, {
        error: null, 
        action_result: action.action_result
    });
};

export const getLabels = (state, action) => {
    return updateObject(state, {
        labels: action.labels
    })
}

export const getPostsByLabel = (state, action) => {
    return updateObject(state, {
        label: action.label
    })
}
//----------------------------------------------

const rerucer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DELETE_START: return deleteStart(state, action);
        case actionTypes.DELETE_SUCCESS: return deleteSuccess(state, action);
        case actionTypes.DELETE_FAIL: return deleteFail(state, action);
        case actionTypes.GET_POSTS_START: return getPostsStart(state, action);
        case actionTypes.GET_POSTS_SUCCESS: return getPostsSuccess(state, action);
        case actionTypes.GET_POSTS_FAIL: return getPostsFail(state, action);
        case actionTypes.GET_POST_SUCCESS: return getPostSuccess(state, action);
        case actionTypes.GET_POST_FAIL: return getPostFail(state, action);
        case actionTypes.GET_LABELS: return getLabels(state, action);
        case actionTypes.GET_POSTS_BY_LABEL: return getPostsByLabel(state,action);
        default: return state; 
    }
}

export default rerucer; 