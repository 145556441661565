import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as customer_action from '../../store/actions/cus_action';
import * as customer_cleaner_action from '../../store/actions/cuscleaner_action';
import * as CONST from '../../common/constant';
import { event } from 'jquery';

class CustomerDetail extends Component {
    state = {
        customer: {
            serviceFrequency: "",
            startedworkingday: "",
            furtherinstruction: "",
            address:"",
            active:"",
            cleanerprice:"",
        },
        isUpdateAddress:false, 
        isUpdateWorkingDateTime: false, 
        isUpdateFrequency: false, 
        isUpdateInstruction: false,
        isDelete: false, 
        isCPrice: false,
    }

    componentDidMount = () => {
        if(!this.props.customercleaner){
            this.props.onGetCustomerCleaner();
        }

        var action = localStorage.getItem("UpdateAction");
        switch (action) {
            case "WorkingDateTime":
                this.setState({isUpdateWorkingDateTime:true});
                break;
            case "Address": 
                this.setState({isUpdateAddress:true})
                break; 
            case "ServiceFrequency":
                this.setState({isUpdateFrequency:true});
                break; 
            case "Instruction":
                this.setState({isUpdateInstruction:true})
                break; 
            case "Delete":
                this.setState({ isDelete: true })
                break;   
            case "CPrice":
                this.setState({isCPrice: true})              
                break; 
            default:
                break;
        }
    }

    eventValueChangeHandler = (event) => {
        let updatedCustomer = {...this.state.customer};
        let updatedvalue = event.target.value; 
        updatedCustomer[event.target.id] = updatedvalue;
        this.setState({customer: updatedCustomer});
    }

    updateCustomerHandler =(event)=> {
        event.preventDefault();
        var customerid = localStorage.getItem(CONST.CUSTOMER_ID);
        //Before updating new startedworkingday, we need to check whether this client has been assigned to
        //any cleaners or not. 
        //If this customer has been assigned to a cleaner, and the work has already commenced, we do nothing. 
        //If the work is going to be commenced, we need to delete the old assignment. 
        let oldcustomer = {...this.props.customer}; 
        var oldstartedworkingdate = new Date(oldcustomer["startedworkingday"]);
        var today = new Date();
        // console.log(this.comparedateHandler(oldstartedworkingdate,today)); 
        if(oldstartedworkingdate.getTime()>=today.getTime() || this.comparedateHandler(oldstartedworkingdate,today)){
            //We need to delete (OR UPDATE) this assignment in the customercleaner table.
            //But we have to check if it has been assigned or not. (By using customerid and date) 
            var cuscleanerkeys = [];
            cuscleanerkeys = Object.keys(this.props.customercleaner).filter(key => {
                var workingdate = new Date(this.props.customercleaner[key].workingdate);
                
                return ((this.props.customercleaner[key].customerid === customerid)
                        &&(this.comparedateHandler(oldstartedworkingdate,workingdate)))
            })
            if(cuscleanerkeys){                
                cuscleanerkeys.map(key => {
                    this.props.onDeleteCustomerCleaner(key);
                })
            }
        }

        let updatedCustomer = {...this.props.customer}; 

        var action = localStorage.getItem("UpdateAction");
        switch (action) {
            case "WorkingDateTime":
                updatedCustomer["startedworkingday"] = this.state.customer.startedworkingday;
                break;
            case "Address": 
            updatedCustomer["address"] = this.state.customer.address;
                break; 
            case "ServiceFrequency":
                updatedCustomer["serviceFrequency"] = this.state.customer.serviceFrequency;
                break; 
            case "Instruction":
                updatedCustomer["furtherinstruction"] = this.state.customer.furtherinstruction;
                break; 
            case "Delete":
                updatedCustomer["active"] = this.state.customer.active; 
                break;   
            case "CPrice": 
                updatedCustomer["cleanerprice"] = this.state.customer.cleanerprice; 
                break; 
            default:
                break;
        }

        this.props.onUpdateCustomer(customerid,updatedCustomer, this.props.token);
        this.props.history.push(CONST.LINK_CUSTOMER);
    }

    comparedateHandler = (d1, d2) => {
        return (d1.getMonth()===d2.getMonth())
                &&(d1.getFullYear()===d2.getFullYear())
                &&(d1.getDate()===d2.getDate())
    }

    render(){
        let updateCustomerControl = null; 

        if(this.props.customer){
            updateCustomerControl = (<div>
                <label><b>Customer Name :</b> {this.props.customer.name}</label><br/>
                {/* Current Information */}
                <label><b>Current Address :</b> {this.props.customer.address}</label><br/>
                <label><b>Current Working Date :</b> {this.props.customer.startedworkingday}</label><br/>
                <label><b>Current Instruction :</b> {this.props.customer.furtherinstruction}</label><br/>

                {/* New Information */}
                {this.state.isUpdateAddress?<label>New Address : </label>:null}
                {this.state.isUpdateAddress?<textarea type="textarea" onChange={(event) => this.eventValueChangeHandler(event)} id="address" />:null}

                {this.state.isUpdateWorkingDateTime?<label>New Working Date : </label>:null}
                {this.state.isUpdateWorkingDateTime?<input type="datetime-local" onChange={(event) => this.eventValueChangeHandler(event)} id="startedworkingday"/>:null}
                <br/>

                {this.state.isUpdateFrequency?<select onChange={(event) => this.eventValueChangeHandler(event)} id="serviceFrequency">
                    <option key="0" value="">---Select Service Frequency---</option>
                    <option key="1" value="Weekly">Weekly</option>
                    <option key="2" value="Fortnightly">Fortnightly</option>
                    <option key="3" value="Threeweeks">Three Weeks</option>
                    <option key="4" value="Onemonth">One Month</option>
                    <option key="5" value="Oneoff">One Off</option>
                </select>:null}

                {this.state.isUpdateInstruction?<label>New Further Instruction: </label>:null}
                {this.state.isUpdateInstruction?<textarea type="textarea" onChange={(event) => this.eventValueChangeHandler(event)} id="furtherinstruction" />:null}
                <br/>

                {this.state.isDelete?<label>Select Inactive option to delete this customer: </label>:null}
                <br/>
                {this.state.isDelete?<select onChange={(event) => this.eventValueChangeHandler(event)} id="active" >
                    <option key="0" value="">---Select Customer Status</option>
                    <option key="1" value="active">Active</option>
                    <option key="2" value="inactive">Inactive</option>
                </select>:null}
                <br/>
                {this.state.isCPrice?<input type="text" onChange={(event) => this.eventValueChangeHandler(event)}
                 id="cleanerprice" name="cleanerprice"></input>:null}<br/>
                <button type="submit">Submit</button>
            </div>)
        }
        return(
            <form onSubmit={this.updateCustomerHandler}>
               <h2>Customer Detail</h2>
                {updateCustomerControl}
            </form>
        )
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        customer: state.customer.customer,
        customercleaner: state.cuscleaner.customercleaner, 
        token: state.auth.token
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onUpdateCustomer: (id, data, token)=> dispatch(customer_action.updateCustomer(id, data, token)),
        onGetCustomerCleaner: () => dispatch(customer_cleaner_action.getCustomerCleaner()),
        onDeleteCustomerCleaner: (id, token) => dispatch(customer_cleaner_action.deleteCustomerCleaner(id, token))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CustomerDetail); 