import * as actionTypes from './actionTypes';
import axios from 'axios';

export const get_cc_start = () => {
    return {
        type: actionTypes.GET_CLEANERS_START
    }
};
export const save_cc_start = () => {
    return {
        type: actionTypes.SAVE_CUS_CLEANER_START
    }
};


export const get_cc_success = (data) => {
    return {
        type: actionTypes.GET_CUS_CLEANER_SUCCESS,
        customercleaner: data
    }
};
export const save_cc_success = (data) => {
    return {
        type: actionTypes.SAVE_CUS_CLEANER_SUCCESS,
        customercleaner: data
    }
};

export const get_cc_fail = (error) => {
    return {
        type: actionTypes.GET_CLEANERS_FAIL,
        error: error
    }
};
export const save_cc_fail = (error) => {
    return {
        type: actionTypes.SAVE_CUS_CLEANER_FAIL,
        error: error
    }
};

export const delete_cc_start = () =>{
    return {
        type: actionTypes.DELETE_CUS_CLEANER_START
    }
}
export const delete_cc_success = () =>{
    return {
        type: actionTypes.DELETE_CUS_CLEANER_SUCCESS
    }
}
export const delete_cc_fail = (error) =>{
    return {
        type: actionTypes.DELETE_CUS_CLEANER_FAIL,
        error: error
    }
}
export const update_cc_fail = (error) => {
    return {
        type: actionTypes.UPDATE_CUS_CLEANER_FAIL,
        error: error
    }
}

export const getCustomerCleaner = () => {
    return dispatch => {
        dispatch(get_cc_start());
        axios.get('/customercleaner.json')
            .then(res => {
                dispatch(get_cc_success(res.data));
            })
            .catch(error => {
                dispatch(get_cc_fail(error));
            })
    }
};
export const createCustomerCleaner = (data, token) => {
    return dispatch => {
        dispatch(save_cc_start());
        axios.post('/customercleaner.json?auth=' + token,data)
            .then(res => {
                dispatch(getCustomerCleaner()); 
            })
            .catch(error => {
                dispatch(save_cc_fail(error));
            })
    }
};

export const deleteCustomerCleaner = (id, token) => {
    return dispatch => {
        dispatch(delete_cc_start());
        axios.delete('/customercleaner/' + id + '.json?auth=' + token)
        .then(()=>{
            dispatch(getCustomerCleaner());
        })
        .catch(error =>{
            dispatch(delete_cc_fail(error));
        })
    }
}

export const updateCustomerCleaner = (id, data, token) => {
    return dispatch => {
        axios.put('/customercleaner/' + id + '.json?auth=' + token, data)
        .then(()=>{
            dispatch(getCustomerCleaner());
        })
        .catch(error =>{
            dispatch(update_cc_fail(error));
        })
    }
}