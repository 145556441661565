import * as actionTypes from './actionTypes';
import * as CONST from '../../common/constant';
import axios from 'axios';

export const auth_start = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const auth_success = (token, userid, email) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token, 
        userId: userid,
        email: email, 
        error: false
    }
};

export const auth_fail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: true
    }
};

export const logout = () => {
    localStorage.removeItem(CONST.TOKEN);
    localStorage.removeItem(CONST.EXPIRE_DATE);
    localStorage.removeItem(CONST.USER_ID);
    localStorage.removeItem(CONST.USER_NAME);
    return {
        type: actionTypes.LOGOUT
    }
}

export const auth = (email, password) => {
    return dispatch => {
        dispatch(auth_start());

        const authData = {
            email: email,
            password: password,
            returnSecureToken: true
        } 
        
        axios.post(CONST.SIGN_UP_URL, authData)
            .then(res => {
                const expirationDate = new Date(new Date().getTime() + res.data.expiresIn * 6000);
                localStorage.setItem(CONST.TOKEN, res.data.idToken);
                localStorage.setItem(CONST.EXPIRE_DATE, expirationDate);
                localStorage.setItem(CONST.USER_ID, res.data.localId);
                localStorage.setItem(CONST.USER_NAME, res.data.email);
                dispatch(auth_success(res.data.idToken, res.data.localId, res.data.email));
            })
            .catch(error => {
                dispatch(auth_fail(error));
            })
    }
};

export const checkAuthState = () => {
    return dispatch => {
        const token = localStorage.getItem(CONST.TOKEN);
        if(!token){
            dispatch(logout());
        } else{
            const expirationDate = new Date(localStorage.getItem(CONST.EXPIRE_DATE));
            if(expirationDate <= new Date()){
                dispatch(logout());
            } else {
               dispatch(auth_success(token,localStorage.getItem(CONST.USER_ID),localStorage.getItem(CONST.USER_NAME)));
            }
        }
    };
};
