import React from 'react';
import classes from './home.module.css';
import * as CONST from '../../common/constant';

const home = () => (
   <div className={classes.home}>
        <a href={CONST.LINK_PTE} className={classes.btnhomenav} style={{backgroundColor:"#f14e4e"}}>PTE</a>
        <a href={CONST.LINK_SCHEDULE} className={classes.btnhomenav} style={{backgroundColor:"#f1bb4e"}}>Schedule</a>
        <a href={CONST.LINK_ASSIGNMENT} className={classes.btnhomenav} style={{backgroundColor:"#84f14e"}}>Assignment</a>
        <a href={CONST.LINK_CUSTOMER}className={classes.btnhomenav} style={{backgroundColor:"#4ef18f"}}>Customers</a>
        <a href={CONST.LINK_CLEANER} className={classes.btnhomenav} style={{backgroundColor:"#4e9af1"}}>Cleaners</a>
        <a href={CONST.NEW_POST_PATH}className={classes.btnhomenav} style={{backgroundColor:"#9a4ef1"}}>Posts</a>
   </div>
)
export default home; 