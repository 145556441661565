import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../utility';

const initialState = {
    customers: null, 
    customer: null,
    error: false
}

export const getCustomersStart = (state) => {
    return updateObject(state);
};

export const getCustomersSuccess = (state, action) => {
    return updateObject(state, {
        customers: action.customers,
    });
};

export const getCustomersFail = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};


//-----
// GET ONE CUSTOMER
//-----
export const getCustomerSuccess = (state, action) => {
    return updateObject(state, {
        customer: action.customer,
    });
};

export const getCustomerFail = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};


//-----
// NEW CUSTOMER
//-----
export const createCustomerStart = (state) => {
    return updateObject(state);
};

export const createCustomerSuccess = (state, action) => {
    return updateObject(state, {
        customers: action.customers
    });
};

export const createCustomerFail = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

//-----
// DELETE CUSTOMER
//-----

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CUSTOMERS_START: return getCustomersStart(state, action); 
        case actionTypes.GET_CUSTOMERS_SUCCESS: return getCustomersSuccess(state, action);
        case actionTypes.GET_CUSTOMERS_FAIL: return getCustomersFail(state, action);
        case actionTypes.CREATE_CUSTOMER_START: return createCustomerStart(state, action); 
        case actionTypes.CREATE_CUSTOMER_SUCCESS: return createCustomerSuccess(state, action);
        case actionTypes.CREATE_CUSTOMER_FAIL: return createCustomerFail(state, action);
        case actionTypes.GET_CUSTOMER_SUCCESS: return getCustomerSuccess(state, action);
        case actionTypes.GET_CUSTOMER_FAIL: return getCustomerFail(state, action);

        default: return state;
    }
};

export default reducer; 