import React, {Component} from 'react';
import classes from './postdetails.module.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.css';
import Spinner from 'react-bootstrap/Spinner';

import calendar_icon from '../../../img/calendar_icon.jpeg';
import delete_icon from '../../../img/delete_icon.png';
import edit_icon from '../../../img/edit_icon.png';
import {connect} from 'react-redux';
import * as post_actions from '../../../store/actions/post_actions';
import * as action_types from '../../../store/actions/actionTypes';

class PostDetails extends Component {
    componentDidMount () {
        let postid = this.props.match.params.postid.split('+')[1];
        this.props.onSelectPost(postid);
    }
    componentDidUpdate () {
        if (this.props.action_result === action_types.GET_POST_SUCCESS) {
            let body = document.getElementById('divbody');
            if (this.props.post && body)
                body.innerHTML = this.props.post.body;
        }
        if(this.props.action_result === action_types.DELETE_SUCCESS)
            this.props.history.push('/');
    }

    deletePostHandler = () => {
        this.props.onDeletePost(this.props.postid, this.props.authToken);
    }
    render(){
        if(this.props.loading){
            return (
                <div className={classes.spinner_background}>
                    <div className={classes.spinner_custom}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                </div>
            );
        }
        let postDetail = null;
        const postData = this.props.post;
        if(postData){
            postDetail = (
                <div className={classes.post_details}>
                    <h2><b>{postData.title}</b></h2><br />
                    <div id={'divbody'} className={classes.divbody}/><br />
                    <span className={classes.postinfo}>
                        <img src={calendar_icon} className={classes.postinfoicon} alt=''/> {postData.date}
                        {this.props.authToken?
                            <img className={classes.postinfoicon} 
                            onClick={this.deletePostHandler}
                            src={delete_icon} alt=''/>:null}
                        {this.props.authToken?<img className={classes.postinfoicon} src={edit_icon} alt=''/>:null}
                    </span>
                </div>
            );
        }
        return (
            <Container>
                <Row className={classes.row}>
                    <Col xs={12} md={12} lg={12} className={classes.col}>
                        {postDetail}
                    </Col>
                </Row>
            </Container>
        )    
    }
}
const mapStateToProps = state => {
    return {
        post: state.post.post, 
        postid: state.post.postid,
        authToken: state.auth.token, 
        action_result: state.post.action_result, 
        loading: state.post.loading
    }
}
const mapDispatchToProps = dispatch => {
    return{
        onDeletePost: (id, token) => dispatch(post_actions.deletePost(id, token)), 
        onSelectPost: (id) => dispatch(post_actions.getPost(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostDetails); 