import React from 'react';
import classes from './input.module.css';
const input = (props) => {
    let inputElement = null; 
    const inputClass = [classes.InputElement];

    if(props.invalid && props.shouldValidate && props.touched){
        inputClass.push(classes.Invalid);
    }
    if(props.bold){
        inputClass.push(classes.Bold);
    }

    switch (props.elementType) {
        case ('input'):
            inputElement = <input className={inputClass.join(' ')} 
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}/>
            break;
        case ('textarea'): 
            inputElement = <textarea className={inputClass.join(' ')}   
                {...props.elementConfig} 
                value={props.value}
                onChange={props.changed}/>
            break;
        case ('select'): 
            inputElement = 
                (<select
                    className={inputClass.join(' ')}
                    value={props.value}
                    onChange={props.changed}>
                    {props.elementConfig.options.map(opt => (
                        <option key={opt.value} value={opt.value} 
                            disabled={opt.disabled} 
                            hidden={opt.hidden} selected={opt.selected}>
                            {opt.displayValue}
                        </option>
                    ))}
                </select>);
            break;
        case ('checkbox'):
            inputElement = <input className={inputClass.join(' ')}   
                {...props.elementConfig} 
                value={props.value}
                onChange={props.changed}/>
            break;
        case ('groupcheckbox'):
            inputElement = <fieldset className={inputClass.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}>
                    {props.elementConfig.options.map(checkbox =>(
                        <div key={checkbox.value}>
                            <input type="checkbox" key={checkbox.value} 
                                value={checkbox.value} 
                                checked={checkbox.checked}/>
                            <label>{checkbox.displayValue}</label> 
                        </div>
                    ))}
                </fieldset>
            break;    
        case ('datetime'):
            inputElement = <div>
                <label>{props.elementConfig.placeholder}: </label>
                <input type="datetime-local"
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} /></div>
            break;
        default:
            inputElement = <input className={inputClass.join(' ')} 
                {...props.elementConfig} 
                value={props.value}
                onChange={props.changed}/>
            break;
    }
    return (
        <div className={classes.Input}>
            <label className={classes.Label}>{props.label}</label>
            {inputElement}
        </div>
    )
}
// const input = (props) => {
//     let inputElement = null; 
//     let inputClass = [classes.inputelment];

//     if(props.invalid && props.shouldValidate && props.touched){
//         inputClass.push(classes.Invalid);
//     }
//     switch (props.elementType) {
//         case 'input':
//             inputElement = <input className={inputClass.join(' ')}
//                 {...props.elementConfig}
//                 value={props.value}
//                 onChange={props.changed}/>
//             break;
//         case ('textarea'):
//             inputElement = <textarea className={inputClass.join(' ')}
//                 {...props.elementConfig}
//                 value={props.value}
//                 onChange={props.changed} />
//             break;
//         case ('select'):
//             inputElement = (<select className={inputClass.join(' ')}
//                 value={props.value}
//                 onChange={props.changed}>
//                     {props.elementConfig.options.map(opt=>(
//                         <option key={opt.key} value={opt.value}>
//                             {opt.displayValue}
//                         </option>
//                     ))}
//             </select>)
//             break; 
//         default:
//             inputElement = <input className={inputClass.join(' ')}
//                 {...props.elementConfig}
//                 value={props.value}
//                 onChange={props.changed} />
//             break;
//     }
//     return (
//         <div className={classes.Input}>
//             <label className={classes.Label}>{props.label}</label>
//             {inputElement}
//         </div>
//     )
// }
export default input; 