import React, {Component} from 'react';
import './App.css';
import Layout from '../src/containers/layout/layout';
import {BrowserRouter} from 'react-router-dom';

import {connect} from 'react-redux';
import * as auth_actions from './store/actions/auth_actions';

class App extends Component {
  componentDidMount () {
    this.props.onTryAutoSignUp();
  }
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Layout/>
        </BrowserRouter>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignUp: () => dispatch(auth_actions.checkAuthState())
  }
}

export default connect(null, mapDispatchToProps)(App);
