import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import * as CONST from '../../common/constant';
import AllPosts from '../../components/blogposts/allposts/allposts';
import PostDetails from '../../components/blogposts/viewpost/postdetails';
import NewPost from '../../components/blogposts/newpost/createpost';
import Auth from '../../components/auth/auth';
import Customer from '../../components/customer/customer';
import NewCustomer from '../../components/customer/newcustomer';
import CustomerDetail from '../../components/customer/customerdetail';
import Cleaner from '../../components/cleaner/cleaner';
import Assignment from '../../components/assignment/assignment';
import Schedule from '../../components/schedule/schedule';
import Home from '../../components/home/home';

import classes from './routing.module.css';


const routing = (props) => {
    // When passing an object through routing, whe should create a new variabel then pass it
    // const postsData = props.posts;
    // then in route: allposts = {postsData}
    return (
        <div className={classes.routing}>
            <Route path={CONST.HOME_PATH} exact
                render={(props) => (
                    <Home
                        {...props}/>)} /> 
            <Switch>
            <Route path={CONST.LINK_PTE} exact
                    render={(props) => (
                        <AllPosts {...props} />)} />
                <Route path={CONST.VIEW_POST_PATH} exact
                    render={(props) => (
                        <PostDetails {...props} />)} />
                <Route path={'/labels/:label'} exact
                    render={(props) => (
                        <AllPosts
                        {...props}/>)} /> 
                <Route path={CONST.NEW_POST_PATH} exact
                    render={(props) => (
                        <NewPost
                            {...props}/>)} />
                <Route path={CONST.LOGIN_PATH} exact
                    render={(props) => (
                        <Auth
                            {...props} />)} />
                <Route path={CONST.LOGOUT_PATH} exact
                    render={(props) => (
                        <Auth
                            {...props}
                            isSignOut={true} />)} />
                <Route path={CONST.LINK_CUSTOMER} exact
                    render={(props) => (
                        <Customer {...props} />)} />  
                <Route path={CONST.LINK_NEW_CUSTOMER} exact
                    render={(props) => (
                        <NewCustomer {...props} />)} />              
                <Route path={CONST.LINK_UPDATE_CUSTOMER} exact
                    render={(props) => (
                        <CustomerDetail {...props} />)} />     
                <Route path={CONST.LINK_CLEANER} exact
                    render={(props) => (
                        <Cleaner {...props} />)} />    
                <Route path={CONST.LINK_ASSIGNMENT} exact
                    render={(props) => (
                        <Assignment {...props} />)} />   
                <Route path={CONST.LINK_SCHEDULE} exact
                    render={(props) => (
                        <Schedule {...props} />)} />                                                      
                <Route path='*' exact render={()=> (
                    <Redirect to='/'/>
                )}/>
            </Switch>
        </div>
    );
}
export default routing;