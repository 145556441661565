import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//If you need to combine multiple reducer, add 'combineReducers'
import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk'

//If we have more than one reducer, we need to combine reducers into one. 
import auth_reducer from './store/reducers/auth_reducer';
import post_reducer from './store/reducers/post_reducer';
import cus_reducer from './store/reducers/cus_reducer';
import cleaner_reducer from './store/reducers/cleaner_reducer';
import customer_cleaner_reducer from './store/reducers/cuscleaner_reducer';

import axios from 'axios';

axios.defaults.baseURL = 'https://danyspace-8aeea.firebaseio.com/';
axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth: auth_reducer, 
    post: post_reducer,
    customer: cus_reducer,
    cleaner: cleaner_reducer, 
    cuscleaner: customer_cleaner_reducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
