import React, {Component} from 'react';
import Input from '../../../ui/input/input';
import classes from './newpost.module.css';
import $ from 'jquery';
import axios from 'axios';
import {connect} from 'react-redux';
require('jquery');
require('bootstrap');
require('bootstrap/dist/css/bootstrap.css');
require('summernote/dist/summernote.css');
require('summernote');

class CreatePost extends Component {
    state = {
        htmlEditor: {
            description: '', 
            img: '',
            body: '', 
            valid: false
        },
        formEntries: {
            title: {
                elementType: 'text',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Title',
                }, 
                value: '', 
                validation: {
                    required: true
                }, 
                valid: false, 
                touched: false
            },
            label: {
                elementType: 'text',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Label',
                }, 
                value: '', 
                validation: {
                    required: true
                }, 
                valid: false, 
                touched: false
            }, 
            type: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        {key: '1', value: 'public', displayValue:'public'},
                        {key: '2', value: 'private', displayValue:'private'},
                    ]
                }, 
                value: '', 
                validation: {
                    required: true
                },
                valid: true, 
                touched: false, 
            }
        },
        formIsValid: false
    }
    componentDidMount(){
        $(document).ready(function () {
            $('#summernote').summernote();
          });
    }
    savePostEventHandler = (event) => {
        event.preventDefault();
        let postData = {};
        var markup = $('#summernote').summernote('code');
        
        const img = this.getImageSrcFromHTMLEditor(markup);
        for(let entryIdentifier in this.state.formEntries){
            postData[entryIdentifier] = this.state.formEntries[entryIdentifier].value;
        }
        postData['body'] = markup;
        postData['img'] = img;
        postData['date'] = new Date().toDateString();
        postData['created'] = this.props.userName;

        //Save to db
        axios.post('/posts.json?auth=' + this.props.authToken, postData)
        .then(response => {
            document.location.href =  '/';
        })
    }
    // This function is used for handling changes to form input contorls' value. 
    postEntriesEventHandler = (event, entryId) => {
        let updatedEntries = {
            ...this.state.formEntries
        }
        let updatedElement = {
            ...updatedEntries[entryId]
        }
        updatedElement.touched = true;
        updatedElement.value = event.target.value;
        updatedEntries[entryId] = updatedElement;
        updatedElement.valid = this.checkValidity(updatedElement.value, updatedElement.validation)

        let formValid = true;
        for (let element in this.state.formEntries) {
            formValid = updatedEntries[element].valid && formValid;
        }
        this.setState({ formEntries: updatedEntries, formIsValid: formValid });
    }
     // This function is used to validate input values. 
     checkValidity (value, rules){
        let isValid = true; 
        if(rules.required){
            isValid = value.trim() !== '' && isValid;
        }
        if(rules.mailFormat){
            isValid = value.match(rules.mailFormat) && isValid; 
        }
        if(rules.phoneFormat){
            isValid = value.match(rules.phoneFormat) && isValid; 
        }
        return isValid; 
    }
    
    getImageSrcFromHTMLEditor (markup) {
        let openImgTag = 'src=';
        let endImgTag = 'data-filename';
        let start = markup.indexOf(openImgTag) + openImgTag.length + 1;
        let end = markup.indexOf(endImgTag, start);
        let imgSrc = markup.substring(start, end);
        return imgSrc.replace(/"/g,'');
    }

    render(){
        let postEntries = []; 
        for(let key in this.state.formEntries){
            postEntries.push({
                id: key,
                config: this.state.formEntries[key]
            });
        }
        let form = (
            <form className={classes.postEntry}>
                <h2>Start creating new post: </h2>
                {postEntries.map(entry => (
                    <Input key={entry.id}
                        elementType={entry.config.elementType}
                        elementConfig={entry.config.elementConfig}
                        value={entry.config.value}
                        changed={(event) => this.postEntriesEventHandler(event, entry.id)}
                        touched={entry.config.touched} 
                        shouldValidate = {entry.config.validation}
                        invalid = {!entry.config.valid}/>
                ))}
                <div id="summernote">Enter your post here...</div>
                <button id='submit' onClick={this.savePostEventHandler}>Save Post</button>
            </form>
        )
        return(
            <div>
                {form}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        authToken: state.auth.token, 
        userName: state.auth.email
    }
}
export default connect(mapStateToProps, null)(CreatePost); 