import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as customer_action from '../../store/actions/cus_action';
import Input from '../../ui/input/input';

import * as CONST from '../../common/constant';

class NewCustomer extends Component {
    state = {
        newCustomerForm: {
            title: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        { 
                            value: '',
                            disabled: 'disabled', 
                            hidden: 'hidden', 
                            displayValue:'Title'
                        },
                        { value: 'Mr.', displayValue: 'Mr.' },
                        { value: 'Mrs.', displayValue: 'Mrs.'},
                        { value: 'Ms.', displayValue: 'Ms.' }
                    ], 
                    placeholder: 'Title'
                }, 
                validation: {
                    required: true, 
                }, 
                value: 'Mrs.', 
                valid: true 
            }, 
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'First name'
                }, 
                value: '',
                validation: {
                    required: true, 
                    maxlength: 25
                }, 
                valid: false, 
                touched: false
            },
            phone: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Phone'
                }, 
                value: '',
                validation: {
                    required: true,
                    // eslint-disable-next-line
                    phoneFormat: /^\d{10}$/
                }, 
                valid: false, 
                touched: false
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Email'
                }, 
                value: '',
                validation: {
                    required: false,
                    // eslint-disable-next-line
                    mailFormat: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                }, 
                valid: false, 
                touched: false
            },
            address: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Address...'
                }, 
                value: '',
                validation: {
                    required: true
                }, 
                valid: false, 
                touched: false
            },
            servicetype:{
                elementType: 'select',
                elementConfig: {
                    options: [
                        {value: '', displayValue: 'Select Service Type'},
                        {value: 'Regular House Cleaning', displayValue: 'Regular House Cleaning'},
                        {value: 'Airbnb Cleaning', displayValue: 'Airbnb Cleaning'},
                        {value: 'Carpet Cleaning', displayValue: 'Carpet Cleaning'},
                        {value: 'End Of Lease Cleaning', displayValue: 'End Of Lease Cleaning'},
                        {value: 'Office Cleaning', displayValue: 'Office Cleaning'},
                        {value: 'Window Cleaning', displayValue: 'Window Cleaning'},
                        {value: 'Spring Cleaning', displayValue: 'Spring Cleaning'}
                    ],
                    placeholder: 'Select Service Type'
                    
                },
                validation: {
                    required: true
                }, 
                value: 'Select Servie Type', 
                valid: true
            },
            serviceFrequency:{
                elementType: 'select',
                elementConfig: {
                    options: [
                        {value: '', displayValue: 'Select Service Frequency'},
                        {value: 'Weekly', displayValue: 'Weekly'},
                        {value: 'Fortnightly', displayValue: 'Fortnightly'},
                        {value: 'Threeweeks', displayValue: 'Three Weeks'},
                        {value: 'Monthly', displayValue: 'One Month'},
                        {value: 'Oneoff', displayValue: 'One off'}
                    ],
                    placeholder: 'Service frequency'
                },
                validation: {
                    required: true
                }, 
                value: 'Select Service Frequency', 
                valid: true
            },
            paymentmethod:{
                elementType: 'select',
                elementConfig: {
                    options: [
                        {value: '', displayValue: 'Select Payment Method'},
                        {value: 'Cash', displayValue: 'Cash'},
                        {value: 'Bank Transfer', displayValue: 'Bank Transfer'},
                    ],
                    placeholder: 'Payment Method'
                },
                validation: {
                    required: true
                }, 
                value: 'Payment Method', 
                valid: true
            },
            startedworkingday: {
                elementType: 'datetime',
                elementConfig: {
                    type: 'datetime-local',
                    placeholder: 'Started Working Day'
                }, 
                value: '',
                validation: {
                    required: true, 
                }, 
                valid: false, 
                touched: false
            },
            duration: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Estimated Duration'
                }, 
                value: '',
                validation: {
                    required: false, 
                    maxlength: 2,
                    isnumber: true
                }, 
                valid: true, 
                touched: false
            },
            price: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Booking price'
                }, 
                value: '',
                validation: {
                    required: false, 
                    maxlength: 3,
                    isnumber: true
                }, 
                valid: true, 
                touched: false
            },
            furtherinstruction: {
                elementType: 'textarea',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Further instruction'
                }, 
                value: '', 
                validation: {
                    required: false
                },
                valid: true
            }
        }, 
        isFormValid: false
    }

    elementValueChangedHandler = (event, elementId) =>{
        let updatedCustomerForm = {
            ...this.state.newCustomerForm
        }
        let updatedElement = {
            ...updatedCustomerForm[elementId]
        }
        updatedElement.value = event.target.value; 
        updatedElement.valid = this.checkValidity(updatedElement.value, updatedElement.validation)
        updatedCustomerForm[elementId] = updatedElement;
        updatedElement.touched = true;

        let formValid = true; 
        for(let element in this.state.newCustomerForm){
            formValid = updatedCustomerForm[element].valid && formValid; 
        }

        this.setState({newCustomerForm: updatedCustomerForm, isFormValid:formValid});
        
    }

    newCustomerHandler = (event) => {
        event.preventDefault();
        let newCustomerData = {};
        for(let elementIdentifier in this.state.newCustomerForm){
            newCustomerData[elementIdentifier] = this.state.newCustomerForm[elementIdentifier].value;
        }
        newCustomerData["active"]="active";
        this.props.onCreateCustomer(newCustomerData,this.props.token);
        this.props.history.push(CONST.LINK_CUSTOMER);
    }

    checkValidity (value, rules){
        let isValid = true; 
        if(rules.required){
            isValid = value.trim() !== '' && isValid;
        }
        if(rules.mailFormat){
            isValid = value.match(rules.mailFormat) && isValid; 
        }
        if(rules.phoneFormat){
            isValid = value.match(rules.phoneFormat) && isValid; 
        }
        if(rules.isnumber){
            isValid = !isNaN(value) && isValid;
        }
        return isValid; 
    }

    render(){
        const newCustomerForm = (<div>
            {Object.keys(this.state.newCustomerForm).map(element => (
                <Input key={element}
                    elementType={this.state.newCustomerForm[element].elementType}
                    elementConfig={this.state.newCustomerForm[element].elementConfig}
                    value={this.state.newCustomerForm[element].value}
                    invalid={!this.state.newCustomerForm[element].valid}
                    bold={this.state.newCustomerForm[element].bold}
                    shouldValidate={this.state.newCustomerForm[element].validation}
                    touched={this.state.newCustomerForm[element].touched}
                    changed={(event) => this.elementValueChangedHandler(event, element)} />
            ))}
            <br/>
            <button type="submit" disabled={!this.state.isFormValid}>Submit</button>
        </div>)
         
        return(
            <form onSubmit={this.newCustomerHandler}>
                <p>New Customer</p>
                {!this.props.isAuthenticated ? null: newCustomerForm}
            </form>
        )
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        token: state.auth.token
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onCreateCustomer: (data, token) => dispatch(customer_action.createCustomer(data, token)), 
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(NewCustomer); 