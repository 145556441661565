import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as customer_action from '../../store/actions/cus_action';
import * as cleaner_action from '../../store/actions/cleaner_action';
import * as customer_cleaner_action from '../../store/actions/cuscleaner_action';
import * as classes from './assignment.module.css'

class Assignment extends Component {
    state = {
        assignment: null,
        cleanerid: null, 
        month: new Date().getMonth(),
        year: new Date().getFullYear()
    }
    componentDidMount() {
        if (!this.props.customers)
            this.props.onLoadCustomers();
        if (!this.props.cleaners)
            this.props.onLoadCleaners();
        if (!this.props.customercleaner)
            this.props.onLoadCustomerCleaner();
    }

    getWorkingTimeHandler = (date) => {
        var newDate = new Date(date);
        return newDate.getHours() + ":" + newDate.getMinutes();
    }

    getAssignmentData = () => {
        if(!this.props.customercleaner) return; 
        
        let assignmentkeys = Object.keys(this.props.customercleaner).filter(key => {
            const workingdate = new Date(this.props.customercleaner[key].workingdate);
            return ((this.props.customercleaner[key].cleanerid === this.state.cleanerid)
                 && (parseInt(workingdate.getMonth()) === parseInt(this.state.month))
                 && (parseInt(workingdate.getFullYear())) === parseInt(this.state.year))
        })
        let assignmentdata = []; 
        if(assignmentkeys){
            Object.values(assignmentkeys).map(key => {
                let newitem = {
                    id: key,
                    name: this.props.customers[this.props.customercleaner[key].customerid].name,
                    cleanerprice: this.props.customers[this.props.customercleaner[key].customerid].cleanerprice,
                    address: this.props.customers[this.props.customercleaner[key].customerid].address, 
                    date: this.props.customercleaner[key].workingdate,
                    time: this.getWorkingTimeHandler(this.props.customers[this.props.customercleaner[key].customerid].startedworkingday),
                    note: this.props.customers[this.props.customercleaner[key].customerid].furtherinstruction
                }
                assignmentdata.push(newitem);
            })
        }
        //Should be sorted here? 
        for(var i=0;i<assignmentdata.length;i++){
            for(var j=i+1; j<assignmentdata.length; j++){
                var d1 = new Date(assignmentdata[i].date);
                var d2 = new Date(assignmentdata[j].date);
                var temp; 
                if(d1.getTime()<d2.getTime()){
                    temp = assignmentdata[i]; 
                    assignmentdata[i] = assignmentdata[j];
                    assignmentdata[j] = temp; 
                }
            }
        }
        //To calculate the working hours of a given cleaner. 
        //Get customerid from customer_cleaner table
        //Get customer working time estimation 
        //Then use Reduce to calculate 
        
        this.setState({assignment:assignmentdata});
    }

    cleanerDropdownControlChangeHandler = (event) => {
        this.setState({cleanerid:event.target.value},()=>{
            this.getAssignmentData();
        });
    }

    monthDropdownControlChangeHandler = (event) => {
        this.setState({month:event.target.value},()=>{
            this.getAssignmentData();
        });
    }
    yearDropdownControlChangeHandler = (event) => {
        this.setState({year: event.target.value},()=>{
            this.getAssignmentData();
        })
    }
    getStatusWithDate = (date1) => {
        var date = new Date(date1);
        var today = new Date();
        if((date.getMonth()===today.getMonth())
                &&(date.getFullYear()===today.getFullYear())
                &&(date.getDate()===today.getDate())) return "In Progress";
        else if(date.getTime()>today.getTime()) return "Not Started"
        else return "Done"
    }

    deleteAssignmentHanlder = (event) => {
        event.preventDefault();
        var customercleanerid = event.target.value;
        var result = window.confirm("Delete permanently this assignment?")
        if (result) {
            this.props.onDeleteCustomerCleaner(customercleanerid, this.props.token);

             //These code are used to refresh the list. 
             var newlist = this.state.assignment; 
             console.log(newlist);
            //  this.setState({assignment:assignmentdata});
             for(var i=0; i<newlist.length; i++){
                 if(newlist[i].id === customercleanerid){
                     newlist.splice(i,1);
                     break; 
                 }
             }
            this.setState({assignment:newlist});
        }
    }
    render(){
        let cleanerdropdowncontrol = null; 
        if(this.props.cleaners){
            cleanerdropdowncontrol = (
                <select onChange={(event)=>this.cleanerDropdownControlChangeHandler(event)}>
                    <option value="0">Select Cleaner</option>
                    {Object.keys(this.props.cleaners).map(key => (
                        <option key={key} value={key}>{this.props.cleaners[key].name}</option>
                    ))}
                </select>
            )
        }
        let assignmentcontrol = null; 
        let assignmentdata = this.state.assignment;
        let index = 0; 
        if(assignmentdata){
            assignmentcontrol = <table>
                <tbody>
                    <tr>
                        <th>When</th>
                        <th>Client Name</th>
                        <th>Address</th>
                        <th>Price</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    {assignmentdata.map(item => (
                        <tr key={item.id}>
                            <td>{item.date} -  <span className={classes.time}>{item.time}</span></td>
                            <td>{item.name}</td>
                            <td className={classes.note}><span className={classes.time}>{item.address}</span> - ({item.note}) </td>
                            <td>{item.cleanerprice}</td>
                            <td>{this.getStatusWithDate(item.date)}</td>
                            <td>
                            <button className="button" value={item.id} onClick={(event)=>this.deleteAssignmentHanlder(event)}>Delete</button>
                            </td>
                        </tr>))}
                </tbody>  
            </table>
        }
        
        return(
            <div>
                <h1>Assignment</h1>
                {cleanerdropdowncontrol} 
                <select onChange={(event)=>this.monthDropdownControlChangeHandler(event)} value={this.state.month}>
                    <option value="0">Jan</option>
                    <option value="1">Feb</option>
                    <option value="2">Mar</option>
                    <option value="3">Apr</option>
                    <option value="4">May</option>
                    <option value="5">Jun</option>
                    <option value="6">Jul</option>
                    <option value="7">Aug</option>
                    <option value="8">Sep</option>
                    <option value="9">Oct</option>
                    <option value="10">Nov</option>
                    <option value="11">Dec</option>
                </select>
                <select onChange={(event)=>this.yearDropdownControlChangeHandler(event)} value={this.state.year}>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                </select>
                <br/>
                <span>-------------------------</span>
                {!this.props.isAuthenticated ? null :assignmentcontrol}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        customers: state.customer.customers,        
        cleaners: state.cleaner.cleaners, 
        customercleaner: state.cuscleaner.customercleaner, 
        isAuthenticated: state.auth.token !== null,
        token: state.auth.token
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLoadCustomers: ()=> dispatch(customer_action.getCustomers()),
        onLoadCleaners: ()=> dispatch(cleaner_action.getCleaners()),
        onLoadCustomerCleaner: ()=> dispatch(customer_cleaner_action.getCustomerCleaner()),
        onDeleteCustomerCleaner: (id, token) => dispatch(customer_cleaner_action.deleteCustomerCleaner(id, token))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Assignment); 