import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../utility';

const initialState = {
    cleaners: null, 
    error: false
}

//------------------------------------------------
// GET CLEANERS
//------------------------------------------------
export const getCleanersStart = (state) => {
    return updateObject(state);
};

export const getCleanersSuccess = (state, action) => {
    return updateObject(state, {
        cleaners: action.cleaners
    });
};

export const getCleanersFail = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

//------------------------------------------------
// CREATE CLEANER
//------------------------------------------------
export const createCleanerStart = (state) => {
    return updateObject(state);
};

export const createCleanerSuccess = (state, action) => {
    return updateObject(state, {
        cleaners: action.cleaners
    });
};

export const createCleanerFail = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CLEANERS_START: return getCleanersStart(state, action); 
        case actionTypes.GET_CLEANERS_SUCCESS: return getCleanersSuccess(state, action);
        case actionTypes.GET_CLEANERS_FAIL: return getCleanersFail(state, action);
        case actionTypes.CREATE_CLEANER_START: return createCleanerStart(state, action); 
        case actionTypes.CREATE_CLEANER_SUCCESS: return createCleanerSuccess(state, action);
        case actionTypes.CREATE_CLEANER_FAIL: return createCleanerFail(state, action);
        default: return state;
    }
};

export default reducer; 