import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as customer_action from '../../store/actions/cus_action';
import * as classes from './customer.module.css';
import * as CONST from '../../common/constant';

class Customer extends Component {
    state = {
        customerlist: [],
        searchvalue: ""
    }
    componentDidMount = () => {
        if(!this.props.customers){
            this.props.onGetCustomers();
        }
    }

    getWorkingDateTimeHandler = (date) => {
        let workingdate = new Date(date);
        return workingdate.toDateString() + " ("+ workingdate.getHours() + ":" + workingdate.getMinutes() + ")";
    }
    
    updateCustomer = (event) => {
        let customerid = event.target.value; 
        let action = event.target.name; 
        switch (action) {
            case "WorkingDateTime":
                localStorage.setItem("UpdateAction","WorkingDateTime");
                break;
            case "Address": 
                localStorage.setItem("UpdateAction", "Address");
                break; 
            case "ServiceFrequency":
                localStorage.setItem("UpdateAction", "ServiceFrequency");
                break;
            case "Instruction":
                localStorage.setItem("UpdateAction", "Instruction");
                break;
            case "Delete":
                localStorage.setItem("UpdateAction", "Delete");
                break;   
            case "CPrice":
                localStorage.setItem("UpdateAction", "CPrice");
                break; 
            default:
                break;
        }

        this.props.onGetCustomer(customerid);
        this.props.history.push(CONST.LINK_UPDATE_CUSTOMER);
    }

    deleteCustomer = (event) => {
        event.preventDefault();
        var result = window.confirm("Do you want to delete this customer?")
        if (result) {
            var customerkey = event.target.value; 
            //When delete, we should update a filed in DB, 
            //The record should not be removed as it links to other tables.
            this.props.onDeleteCustomer(customerkey, this.props.token); 

            

            //These code are used to refresh the customers list. 
            var newcustomerlist = this.state.customerlist; 
            for(var i=0; i<newcustomerlist.length; i++){
                if(newcustomerlist[i] === customerkey){
                    newcustomerlist.splice(i,1);
                    break; 
                }
            }
            this.setState({customerlist:newcustomerlist});
        }
    }

    searchcustomerChangeHanlder = (event) => {
        this.setState({searchvalue: event.target.value});
    }

    searchCustomerHanlder = (event) => {
        event.preventDefault();
        var result = Object.keys(this.props.customers).filter(key => {
            return this.props.customers[key].name.includes(this.state.searchvalue.trim());
        })
        this.setState({customerlist: result});
    }
    render(){
        let customerslistControl = null; 
        let customersdata = null; 
        
        if (this.props.customers) {
            if(!this.state.customerlist || this.state.customerlist.length <=0){
                customersdata = Object.keys(this.props.customers);
            }
            else{
                customersdata = this.state.customerlist; 
            }
            let index = 0; 
            customerslistControl = <table className={classes.customers}>
                <tbody>
                    <tr>
                        <th>No.</th>
                        <th>Name</th>
                        <th>Actions</th>
                    </tr>
                    {customersdata.map(cuskey => (
                        <tr key={cuskey}>
                            <td>{index++}</td>
                            <td>{this.props.customers[cuskey].name}</td>
                            <td>
                                <button className={classes.button1} value={cuskey} name="Address" onClick={(event) => this.updateCustomer(event)}>
                                    {this.props.customers[cuskey].address?this.props.customers[cuskey].address:"Update Address"}
                                </button>
                                <button className={classes.button1} value={cuskey} name="Delete" onClick={(event) => this.updateCustomer(event)}>
                                    {this.props.customers[cuskey].active?this.props.customers[cuskey].active:"Inactive"}
                                </button>
                                <button className={classes.button1} value={cuskey} name="ServiceFrequency" onClick={(event) => this.updateCustomer(event)}>
                                    {this.props.customers[cuskey].serviceFrequency.toUpperCase()}
                                </button>
                                <button className={classes.button1}value={cuskey} name="WorkingDateTime" onClick={(event) => this.updateCustomer(event)}>
                                    {this.getWorkingDateTimeHandler(this.props.customers[cuskey].startedworkingday)}
                                </button>
                                <button className={classes.button1} value={cuskey} name="Instruction" onClick={(event) => this.updateCustomer(event)}>Note</button>
                                <button className={classes.button1} value={cuskey} onClick={(event) => this.deleteCustomer(event)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        }         
        return(
            <form onSubmit={this.newCustomerHandler}>
                <h1>Customers List ({this.props.customers?Object.keys(this.props.customers).length:null})</h1>
                <br/>
                <input type="text" length="200px" id="searchcustomer" onChange={(event)=>this.searchcustomerChangeHanlder(event)} placeholder="Type customer name"/>
                <button onClick={(event)=> this.searchCustomerHanlder(event)}>Search</button>
                <br/>
                {!this.props.isAuthenticated ? null:<a href={CONST.LINK_NEW_CUSTOMER}>New Customer</a>}
                {!this.props.isAuthenticated ? null: customerslistControl}
            </form>
        ) 
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        customers: state.customer.customers,
        token: state.auth.token
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGetCustomers: ()=> dispatch(customer_action.getCustomers()),
        onUpdateCustomer: (id, data)=> dispatch(customer_action.updateCustomer(id, data)),
        onGetCustomer: (id) => dispatch(customer_action.getCustomer(id)), 
        onDeleteCustomer: (id, token) => dispatch(customer_action.deleteCustomer(id,token))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Customer); 



{/* <td>
{this.props.customers[cuskey].name}
<button className="button1" value={cuskey} name="Address" onClick={(event) => this.updateCustomer(event)}>
    {this.props.customers[cuskey].address?this.props.customers[cuskey].address:"Update Address"}
</button>
</td>
<td>
<button className="button1" value={cuskey} name="Delete" onClick={(event) => this.updateCustomer(event)}>
    {this.props.customers[cuskey].active?this.props.customers[cuskey].active:"Inactive"}
</button>
</td>
<td>
<button className="button1" value={cuskey} name="ServiceFrequency" onClick={(event) => this.updateCustomer(event)}>
    {this.props.customers[cuskey].serviceFrequency.toUpperCase()}
</button>
</td>
<td>
<button className="button1" value={cuskey} name="WorkingDateTime" onClick={(event) => this.updateCustomer(event)}>
    {this.getWorkingDateTimeHandler(this.props.customers[cuskey].startedworkingday)}
</button>
</td>
<td>
<button className="button1" value={cuskey} name="Instruction" onClick={(event) => this.updateCustomer(event)}>Note</button>
</td> */}