import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../utility';

const initialState = {
    token: null, 
    userId: null, 
    email: null,
    error: false
}

export const authStart = (state, action) => {
    return updateObject(state);
};

export const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.idToken, 
        userId: action.userId, 
        email: action.email,
        error: action.error
    });
};

export const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

export const logout = (state, action) => {
    return updateObject(state, {
        token: null, 
        userId: null, 
        email: null, 
        error: false
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action); 
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.LOGOUT: return logout(state, action);
        default: return state;
    }
};

export default reducer; 