import React from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.css';

import Routing from '../../navigation/routing/routing';
import About from '../../components/aboutme/aboutme';
import Footer from '../footer/footer';
import Nabar from '../../navigation/navigation';
import Labels from '../../components/labels/labels';


const layout = (props) => {
    return (
        <Container>
            <Row>
                <Col>
                    <Nabar />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={12} lg={9} xl={9}>
                    <Routing posts={props.posts} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={3} xl={3} >
                    <Labels />
                    <About />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Footer />
                </Col>
            </Row>
        </Container>
    );
}
export default layout;


//xs <576px
//sm >=576px
//md >=768px
//lg >=992px
//xl >=1200px