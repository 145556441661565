import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as cleaner_action from '../../store/actions/cleaner_action';
import Input from '../../ui/input/input';
import * as classes from '../customer/customer.module.css';

class Cleaner extends Component {
    state = {
        newCleanerForm : {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Name'
                }, 
                value: '',
                validation: {
                    required: true, 
                    maxlength: 25
                }, 
                valid: false, 
                touched: false
            },
            phone: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Phone'
                }, 
                value: '',
                validation: {
                    required: true,
                    // eslint-disable-next-line
                    phoneFormat: /^\d{10}$/
                }, 
                valid: false, 
                touched: false
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Email'
                }, 
                value: '',
                validation: {
                    required: false,
                    // eslint-disable-next-line
                    mailFormat: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                }, 
                valid: false, 
                touched: false
            },
            address: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Address...'
                }, 
                value: '',
                validation: {
                    required: true
                }, 
                valid: false, 
                touched: false
            },
            workday:{
                elementType: 'groupcheckbox',
                elementConfig: {
                    type: 'groupcheckbox', 
                    options: [
                        {value: 'Monday', displayValue: 'Monday'},
                        {value: 'Tuesday', displayValue: 'Tuesday'},
                        {value: 'Wednesday', displayValue: 'Wednesday'},
                        {value: 'Thursday', displayValue: 'Thursday'},
                        {value: 'Friday', displayValue: 'Friday'},
                        {value: 'Saturday', displayValue: 'Saturday'},
                        {value: 'Sunday', displayValue: 'Sunday'}
                    ]
                },
                validation: {
                    required: false
                }, 
                value: ''
            },
            note: {
                elementType: 'textarea',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Further instruction'
                }, 
                value: 'Further instruction', 
                validation: {
                    required: false
                },
                valid: true
            }
        }, 
        isFormValid: false,
        workdays: []
    }
    componentDidMount = () => {
        if(!this.props.cleaners)
            this.props.onGetCleaners();
    }

    elementValueChangedHandler = (event, elementId) =>{
        if(elementId === 'workday'){
            let workdays = this.state.workdays;
            event.target.checked?workdays.push(event.target.value):workdays.splice(workdays.indexOf(event.target.value),1);
            this.setState({workdays:workdays});
        }
        let updatedCleanerForm = {
            ...this.state.newCleanerForm
        }
        let updatedElement = {
            ...updatedCleanerForm[elementId]
        }
        if(elementId === 'workday')
            updatedElement.value = this.state.workdays;
        else
            updatedElement.value = event.target.value; 

        updatedElement.valid = this.checkValidity(updatedElement.value, updatedElement.validation)
        updatedCleanerForm[elementId] = updatedElement;
        updatedElement.touched = true;

        let formValid = true; 
        for(let element in this.state.newCleanerForm){
            formValid = updatedCleanerForm[element].valid && formValid; 
        }
        
        this.setState({newCleanerForm: updatedCleanerForm, isFormValid:formValid});
    }

    newCleanerHandler = (event) => {
        event.preventDefault();
        let newCleanerData = {};
        for(let elementIdentifier in this.state.newCleanerForm){
            newCleanerData[elementIdentifier] = this.state.newCleanerForm[elementIdentifier].value;
        }
       this.props.onCreateCleaner(newCleanerData, this.props.token);
    }

    checkValidity (value, rules){
        let isValid = true; 
        if(rules.required){
            isValid = value.trim() !== '' && isValid;
        }
        if(rules.mailFormat){
            isValid = value.match(rules.mailFormat) && isValid; 
        }
        if(rules.phoneFormat){
            isValid = value.match(rules.phoneFormat) && isValid; 
        }
        if(rules.isnumber){
            isValid = !isNaN(value) && isValid;
        }
        return isValid; 
    }

    changeWorkdayHandler = (event) =>{

    }

    render(){
        let cleanerscontrol = null; 
        if(this.props.cleaners){
            cleanerscontrol = Object.keys(this.props.cleaners).map(ckey => (
                <div key={ckey} className={classes.cusinfo}>
                    <b>{this.props.cleaners[ckey].name}</b> , {this.props.cleaners[ckey].phone},
                    {Object.keys(this.props.cleaners[ckey].workday).map(day => (
                        <div key={day} className={classes.weekdays}>
                            <input type='checkbox' key={day} checked={true} onChange={this.changeWorkdayHandler}/> <label> {this.props.cleaners[ckey].workday[day]}</label>
                        </div>    
                    ))}
                </div>
            ))
        }

        const newCleanerForm = (<div>
            {Object.keys(this.state.newCleanerForm).map(element => (
                <Input key={element}
                    elementType={this.state.newCleanerForm[element].elementType}
                    elementConfig={this.state.newCleanerForm[element].elementConfig}
                    value={this.state.newCleanerForm[element].value}
                    invalid={!this.state.newCleanerForm[element].valid}
                    bold={this.state.newCleanerForm[element].bold}
                    shouldValidate={this.state.newCleanerForm[element].validation}
                    touched={this.state.newCleanerForm[element].touched}
                    changed={(event) => this.elementValueChangedHandler(event, element)} />
            ))}
            <button type="submit" disabled={!this.state.isFormValid}>Submit</button>
        </div>)
         
        return(
            <form onSubmit={this.newCleanerHandler}>
                <h1>Cleaners List ({this.props.cleaners? Object.keys(this.props.cleaners).length: null})</h1>
                {this.props.cleaners?cleanerscontrol:null}
                <br/>
                <p>Create New Cleaner</p>
                {!this.props.isAuthenticated? null: newCleanerForm}
            </form>
        )
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        cleaners: state.cleaner.cleaners, 
        token: state.auth.token
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGetCleaners: ()=> dispatch(cleaner_action.getCleaners()),
        onCreateCleaner: (data, token) => dispatch(cleaner_action.createCleaner(data, token))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Cleaner); 