import React, {Component} from 'react';
import classes from './post.module.css';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.css';
import * as CONST from '../../../common/constant';
import delete_icon from '../../../img/delete_icon.png';
import edit_icon from '../../../img/edit_icon.png';
import {Link} from 'react-router-dom';

import AUX from '../../../containers/aux/aux';

import {connect} from 'react-redux';
import * as post_actions from '../../../store/actions/post_actions';
import Row from 'react-bootstrap/Row';

class PostItem extends Component { 
    onDeletePostHandler = () =>{
        //Should display a confirmation popup. 
        this.props.onDeletePost(this.props.postid, this.props.token);
    }
    render(){
        let postItem = null; 
        const postData = this.props.postData; 
        if(!postData)
            return null; 

        let viewPostPath = null; 
        const isAuthenticated = this.props.token !== null; 
        const isPrivateItem = postData.type === 'private';

        let isViewable = true; 
        if (isPrivateItem) {
            isViewable = isAuthenticated&&isPrivateItem;
        }
        
        viewPostPath = CONST.POST_DETAIL + postData.title.trim().replace(/ /g, '-').replace('?', '') + '+' + this.props.postid;
        postItem = (
            <AUX>
                <Row className={classes.container}>
                    <Link to={viewPostPath} className={classes.post_title}>
                        {postData.title}
                    </Link>
                </Row>
                <Row className={classes.container}>
                    {/* <Col xs={12} sm={4} md={4} lg={4} xl={4} className={classes.container}>
                            <img className={classes.post_img_icon}
                                src={postData.img} alt={postData.title} />
                    </Col> */}
                    <Col xs={12} sm={8} md={8} lg={8} xl={8} className={classes.container}>
                            <span className={classes.postinfo}>
                                {!this.props.token ? null :
                                    <img className={classes.postinfoicon}
                                        src={edit_icon} alt='Edit this Post'/>}
                                {!this.props.token ? null :
                                    <img className={classes.postinfoicon}
                                        src={delete_icon} alt='Delete this Post'
                                        onClick={this.onDeletePostHandler} />}
                            </span>
                    </Col>
                </Row>
            </AUX>    
        )
        
        
        return (
           <div>
               {isViewable?postItem: null}
           </div>
        );
    }
} 

const mapStateToProps = state => {
    return{
        token: state.auth.token, 
        error: state.post.error, 
    }
}
const mapDispatchToProps = dispatch => {
    return{
        onDeletePost: (id, token) => dispatch(post_actions.deletePost(id, token))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostItem); 