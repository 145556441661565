import React, {Component} from 'react';
import classes from './auth.module.css';
import Input from '../../ui/input/input';
import * as actions from '../../store/actions/auth_actions';
import {connect} from 'react-redux';
import * as CONST from '../../common/constant';

class Auth extends Component {
    state = {
        controls:{
            email: {
                elementType: 'text',
                elementConfig: {
                    type: 'emails',
                    placeholder: 'Mail Address',
                }, 
                value: '', 
                validation: {
                    required: true
                }, 
                valid: true, 
                touched: false
            },
            password: {
                elementType: 'text',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password',
                }, 
                value: '', 
                validation: {
                    required: true
                }, 
                valid: true, 
                touched: false
            }
        }
    }

    componentDidUpdate(){
        if(this.props.authToken)
            this.props.history.push('/');
    }

    inputChangedEventHandler = (event, ctrid) => {
        let updatedControls = {
            ...this.state.controls
        }
        let updatedInput = {
            ...updatedControls[ctrid]
        }
        updatedInput.value = event.target.value; 
        updatedControls[ctrid] = updatedInput;
        
        this.setState({controls: updatedControls});
    }

    submitHanlder = (event) => {
        event.preventDefault();
        this.props.onLogin(this.state.controls.email.value, this.state.controls.password.value);
    }

    onSignOutEventHandler = () => {
        this.props.onLogout();
        this.props.history.push('/');
    }
    onCancelSignOutEventHandler = () => {
        this.props.history.push('/');
    }

    render(){
        let controls = []; 
        for(let key in this.state.controls){
            controls.push({
                id: key,
                config: this.state.controls[key]
            });
        }
        const inputControls = controls.map(ctr => (
            <Input key={ctr.id} 
                elementType={ctr.config.elementType} 
                elementConfig={ctr.config.elementConfig}
                value={ctr.config.value}
                changed={(event) => this.inputChangedEventHandler(event, ctr.id)}
                touched={ctr.config.touched}
                />));

        let form = (
            <form onSubmit={(event) => this.submitHanlder(event)}>
                <div>
                    {inputControls}
                    <button type='submit'>SUBMIT</button>
                    {this.props.isError ? <label className={classes.err}>Login failed wrong user credentials!</label> : null}
                </div>
            </form>
        );
        if (this.props.isSignOut) {
            form = (
                <div>
                    <span>Are you sure you want to sign out?</span>
                    <button className={classes.button} onClick={this.onSignOutEventHandler}>YES</button>
                    <button className={classes.button} onClick={this.onCancelSignOutEventHandler}>NO</button>
                </div>
            )
        }

        return(
            <div className={classes.auth}>
                {form}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        authToken: state.auth.token, 
        isError: state.auth.error
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLogin: (email, password) => dispatch(actions.auth(email, password)), 
        onLogout: () => dispatch(actions.logout())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);