import React, { Component } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import * as classes from './schedule.module.css';
import {connect} from 'react-redux';
import * as customer_action from '../../store/actions/cus_action';
import * as cleaner_action from '../../store/actions/cleaner_action';
import * as customer_cleaner_action from '../../store/actions/cuscleaner_action';
import ScheduleControl from './schedulecontrol/schedulecontrol';

class Schedule extends Component {
    state = {
        selectedCustomers: null, //To keep filtered customer by a specific date
        selectedDate: null,
        maxdays: 7
    }
    componentDidMount (){
        if(!this.props.customers)
            this.props.onLoadCustomers();        
        if(!this.props.cleaners)
            this.props.onLoadCleaners();
        if(!this.props.customercleaner)
            this.props.onLoadCustomerCleaner();
    }

    CustomToggle = ({ children, eventKey }) => {
        const decoratedOnClick = useAccordionToggle(eventKey, () => {
            let data = this.getCustomersBySelectedDate(eventKey);
            this.setState({selectedCustomers:data});
            this.setState({selectedDate:eventKey});
        });
        return (
            <div>
                <button type="button" className={classes.AccordionButton}
                    disabled={this.props.disabledAccordionBtn}
                    onClick={decoratedOnClick}>{children}</button>
            </div>
        );
    }

    getCustomersBySelectedDate = date => {
        let customers = {...this.props.customers};
        return Object.keys(customers).filter(key => {
            let customerstatus = customers[key].active;
            return this.getScheduledSustomerByDate(customers[key].startedworkingday,date,customers[key].serviceFrequency)&&(customerstatus==="active");
        });
    }

    getScheduledSustomerByDate = (working_date, selected_date, service_frequency) => {
        const selectedDate = new Date(selected_date);
        let workingDate = new Date(working_date);

        while (workingDate < selectedDate) {
            switch (service_frequency.toUpperCase()) {
                case ('WEEKLY'):
                    workingDate = new Date(workingDate.setDate(workingDate.getDate() + 7));
                    break;
                case ('FORTNIGHTLY'):
                    workingDate = new Date(workingDate.setDate(workingDate.getDate() + 14));
                    break;
                case ('THREEWEEKS'):
                    workingDate = new Date(workingDate.setDate(workingDate.getDate() + 21));
                    break;
                case ('MONTHLY'):
                    workingDate = new Date(workingDate.setDate(workingDate.getDate() + 28));
                    break;    
                default:
                    return (this.comparedateHandler(workingDate.toDateString(),selectedDate.toDateString()))         
            }
        }
        return (this.comparedateHandler(workingDate.toDateString(),selectedDate.toDateString()))         
    }

    getAssignedCleanersByCustomer = (customerid) => {
        if(!this.props.customercleaner) return null; 
        const cusclean = this.props.customercleaner;
        var found  = Object.keys(cusclean).filter(key => {
            return (cusclean[key].customerid === customerid)
                        &&(this.comparedateHandler(cusclean[key].startedworkingday, this.state.selectedDate))
        });
        return found; 
    }

    getDayHanlder = (date) => {
        var newdate = new Date(date);
        return newdate.toDateString();
    }

   comparedateHandler = (date1, date2) => {
        var d1 = new Date(date1);
        var d2 = new Date(date2);
        return (d1.getMonth()===d2.getMonth())
                &&(d1.getFullYear()===d2.getFullYear())
                &&(d1.getDate()===d2.getDate())
    }

    schedulePeriodHandler = (event) => {
        this.setState({maxdays:event.target.value});
    }

    getClassDayHandler = (day) => {
        var selected_date = new Date(day);
        var PPMP20019_startdate = new Date("Thu Mar 11 2021"); //this class is fortnight
        //SUN = 0
        //MON = 1
        //TUE = 2
        //WED = 3
        //THU = 4
        if(selected_date.getDay()===2) return "20014,20029";
        if(selected_date.getDay()===3) return "20014";
        if(selected_date.getDay()===4){
            while(PPMP20019_startdate < selected_date){
                PPMP20019_startdate = new Date(PPMP20019_startdate.setDate(PPMP20019_startdate.getDate() + 14));
            }
            if ((PPMP20019_startdate.getMonth()===selected_date.getMonth())
            &&(PPMP20019_startdate.getFullYear()===selected_date.getFullYear())
            &&(PPMP20019_startdate.getDate()===selected_date.getDate()))
                return "20019"
        }
        return null; 
    }

    render(){
        let schedule; 
        if(this.state.selectedCustomers){
            let selectedCustomerKeys = this.state.selectedCustomers; 
            schedule = selectedCustomerKeys.map(cuskey =>{
                return(
                    <ScheduleControl key={cuskey}
                        customer={this.props.customers[cuskey]}
                        customerkey={cuskey}
                        cleaners={this.props.cleaners}
                        customercleaner={this.props.customercleaner} 
                        workingdate = {this.state.selectedDate}/>   
                )
            })
        }

        let calendarcontrol = [];
        var today = new Date();
        var nextDate = null; 
        calendarcontrol.push(today.toDateString());
        for(var i=0;i<this.state.maxdays;i++){
            nextDate = new Date(today.setDate(today.getDate()+1));
            calendarcontrol.push(nextDate.toDateString());
        }
        
        var accordioncontrol = (<Accordion>
            {calendarcontrol.map(day => (
                <Card key={day}>
                    <this.CustomToggle eventKey={day}>
                        {this.getDayHanlder(day)} ({this.getCustomersBySelectedDate(day).length}) {<span className={classes.dayclass}>{this.getClassDayHandler(day)}</span>}
                </this.CustomToggle>
                    <Accordion.Collapse eventKey={day}>
                        <Card.Body>
                            {schedule ? schedule : null}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            ))}
        </Accordion>);

        return(
            <div className={classes.scheduleinfo}>
                 <h1>Cleaning Schedule</h1>
                <select onChange={(event)=>this.schedulePeriodHandler(event)}>
                    <option value="7">One Week</option>
                    <option value="14">Two Weeks</option>
                    <option value="21">Three Week</option>
                    <option value="28">One Month</option>
                </select>
                <br/>
                {!this.props.isAuthenticated ? null : accordioncontrol}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        customers: state.customer.customers,        
        cleaners: state.cleaner.cleaners, 
        customercleaner: state.cuscleaner.customercleaner, 
        isAuthenticated: state.auth.token !== null,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLoadCustomers: ()=> dispatch(customer_action.getCustomers()),
        onLoadCleaners: ()=> dispatch(cleaner_action.getCleaners()),
        onLoadCustomerCleaner: ()=> dispatch(customer_cleaner_action.getCustomerCleaner())
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Schedule); 