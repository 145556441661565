//For calling firebase API
export const SIGN_IN_URL = 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyCSM1VMN1RSo2SwVChidxgFyO5-QNI5V98';
export const SIGN_UP_URL = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCSM1VMN1RSo2SwVChidxgFyO5-QNI5V98';
//For storing data in local storage
export const TOKEN = 'TOKEN';
export const EXPIRE_DATE = 'EXPIRATION_DATE';
export const USER_ID = 'USER_ID';
export const USER_NAME = 'USER_NAME';

//For routing path
export const HOME_PATH = '/';
export const ENGLISH_TEST_PATH = '/englishtest';
export const VIEW_POST_PATH = '/posts/:postid';
export const POST_DETAIL = '/posts/';
export const ABOUTME_PATH = '/aboutme'
export const NEW_POST_PATH = '/newpost';
export const LOGIN_PATH = '/login';
export const LOGOUT_PATH = '/logout';

export const ACTION_SUCCESS = 'SUCCESS';
export const ACTION_FAIL = 'FAIL';

export const CUSTOMER_ID = "CUSTOMER_ID";
export const LINK_PTE = "/pte";
export const LINK_SCHEDULE = "/schedule";
export const LINK_ASSIGNMENT = "/assignment";
export const LINK_CLEANER = "/cleaner";
export const LINK_CUSTOMER = "/customer"
export const LINK_NEW_CUSTOMER = "/new-customer";
export const LINK_UPDATE_CUSTOMER = "/update-customer";

export const AIRBNB_SERVICE_TYPE = "Airbnb Cleaning";