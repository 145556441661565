import React, {Component} from 'react';
import Post from '../post/post';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.css';
import classes from '../post/post.module.css';
import {connect} from 'react-redux';
import * as posts_actions from '../../../store/actions/post_actions';


class Posts extends Component {
    componentDidMount () {
        const label = this.props.match.params.label; 
        if(!this.props.posts || !label){
            this.props.onLoadPosts();
        }
    }
    render(){
        if(this.props.loading){
            return (
                <div className={classes.spinner_background}>
                    <div className={classes.spinner_custom}>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                </div>
            );
        }
        let postItems = null;
        const allposts = this.props.posts;
        if (allposts) {
            if (Object.keys(allposts)[0].length === 20) {
                postItems = Object.keys(allposts).map(id => (
                    <Post key={id}
                        postData={allposts[id]}
                        postid={id}
                    />
                ))
            } else {
                //This is a tricky way. Because after querying all posts based on label, 
                //We have to change the format of the returned object.
                postItems = Object.keys(allposts).map(id => (
                    <Post key={id}
                        postData={allposts[id].postData}
                        postid={allposts[id].postid}
                    />
                ))
            }
        }
        return (
            <div>
                <Container> 
                    {postItems}
                </Container>
            </div>
        );
    }
} 
const mapStateToProps = state => {
    return {
        posts: state.post.posts, 
        label: state.post.label, 
        loading: state.post.loading
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLoadPosts: ()=> dispatch(posts_actions.getPosts()), 
        onLoadPostsByLabel: (label)=> dispatch(posts_actions.getPostsByLabel(label))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Posts);
