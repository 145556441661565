import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as CONST from '../../common/constant';

//------------------------------------------------
// GET CUSTOMERS
//------------------------------------------------
export const get_customers_start = () => {
    return {
        type: actionTypes.GET_CUSTOMERS_START
    }
};

export const get_customers_success = (customers) => {
    return {
        type: actionTypes.GET_CUSTOMERS_SUCCESS,
        customers: customers
    }
};

export const get_customers_fail = (error) => {
    return {
        type: actionTypes.GET_CUSTOMERS_FAIL,
        error: error
    }
};

export const getCustomers = () => {
    return dispatch => {
        dispatch(get_customers_start());
        axios.get('/customers.json')
            .then(res => {
                dispatch(get_customers_success(res.data));
            })
            .catch(error => {
                dispatch(get_customers_fail(error));
            })
    }
};

//------------------------------------------------
// NEW CUSTOMER
//------------------------------------------------
export const create_customer_start = () => {
    return {
        type: actionTypes.CREATE_CUSTOMER_START
    }
};

export const create_customer_fail = (error) => {
    return {
        type: actionTypes.CREATE_CUSTOMER_FAIL,
        error: error
    }
};

export const createCustomer = (data,token) => {
    return dispatch => {
        dispatch(create_customer_start());
        axios.post('/customers.json?auth=' + token, data)
            .then(() => {
                dispatch(getCustomers());
            })
            .catch(error => {
                dispatch(create_customer_fail(error));
            })
    }
};


//------------------------------------------------
// DELETE CUSTOMER
//------------------------------------------------
export const delete_customer_start = () => {
    return {
        type: actionTypes.DELETE_CUSTOMER_START
    }
};

export const delete_customer_fail = (error) => {
    return {
        type: actionTypes.DELETE_CUSTOMER_FAIL,
        error: error
    }
};

export const deleteCustomer = (id, token) => {
    return dispatch => {
        dispatch(delete_customer_start());
        axios.delete('/customers/' + id + '.json?auth=' + token)
            .then(() => {
                console.log("Customer is deleted");
                dispatch(getCustomers());
            })
            .catch(error => {
                dispatch(delete_customer_fail(error));
            })
    }
};


//------------------------------------------------
// GET ONE CUSTOMER
//------------------------------------------------
export const get_customer_success = (customer) => {
    return {
        type: actionTypes.GET_CUSTOMER_SUCCESS,
        customer: customer
    }
};

export const get_customer_fail = (error) => {
    return {
        type: actionTypes.GET_CUSTOMER_FAIL,
        error: error
    }
};
export const getCustomer = (id) => {
    return dispatch => {
        axios.get('/customers/' + id + '.json')
        .then((res)=> {
            localStorage.setItem(CONST.CUSTOMER_ID,id);
            dispatch(get_customer_success(res.data));
        })
        .catch(error => {
            dispatch(get_customer_fail(error));
        })
    }
}


//------------------------------------------------
// UPDATE CUSTOMER
//------------------------------------------------
export const update_customer_fail = (error) => {
    return {
        type: actionTypes.UPDATE_CUSTOMER_FAIL,
        error: error
    }
};
export const updateCustomer = (id, data, token) => {
    return dispatch => {
        axios.put('/customers/' + id + '.json?auth=' + token, data)
        .then(()=>{
            dispatch(getCustomers());
        })
        .catch(error =>{
            dispatch(update_customer_fail(error));
        })
    }
}