import axios from 'axios';
import * as actionTypes from './actionTypes';

//----------------------------------------------
export const get_post_start = () => {
    return{
        type: actionTypes.GET_POST_START,
        loading: true
    }
}
export const get_post_success = (post, id) => {
    return {
        type: actionTypes.GET_POST_SUCCESS, 
        post: post,
        postid: id, 
        loading: false, 
        action_result: actionTypes.GET_POST_SUCCESS
    }
}
export const get_post_fail = (error) => {
    return { 
        type: actionTypes.GET_POST_FAIL, 
        error: error,
        loading: false, 
        action_result: actionTypes.GET_POST_FAIL
    }
}
//----------------------------------------------

export const get_posts_start = () => {
    return {
        type: actionTypes.GET_POSTS_START, 
        loading: true
    }
}

export const get_posts_success = (posts) => {
    return {
        type: actionTypes.GET_POSTS_SUCCESS,
        posts: posts,
        loading: false,
        action_result: actionTypes.GET_POSTS_SUCCESS
    }
}

export const get_posts_fail = (error) => {
    return {
        type: actionTypes.GET_POSTS_FAIL,
        error: error,
        loading: false, 
        action_result: actionTypes.GET_POSTS_FAIL
    }
}
//----------------------------------------------

export const delete_start = () => {
    return {
        type: actionTypes.DELETE_START
    }
}

export const delete_success = () => {
    return {
        type: actionTypes.DELETE_SUCCESS, 
        action_result: actionTypes.DELETE_SUCCESS
    }
}

export const delete_failed = (error) => {
    return {
        type: actionTypes.DELETE_FAIL, 
        error: error, 
        action_result: actionTypes.DELETE_FAIL
    }
}

export const get_lables = (labels) => {
    return{
        type: actionTypes.GET_LABELS,
        labels: labels, 
        action_result: actionTypes.GET_LABELS_SUCCESS
    }
}

//----------------------------------------------


export const deletePost = (id, token) => {
    return dispatch => {
        dispatch(delete_start());
        axios.delete('/posts/' + id + '.json?auth=' + token)
        .then(res=>{
            dispatch(delete_success());
            dispatch(getPosts());
        })
        .catch(error =>{
            dispatch(delete_failed(error));
        })
    }
}

export const getPosts = () => {
    return dispatch => {
        dispatch(get_posts_start());
        axios.get('/posts.json')
        .then(res =>{
            dispatch(get_posts_success(res.data));
            const posts = res.data;
            let labels = [];
            // eslint-disable-next-line
            Object.values(posts).map(post => {
                if(labels.indexOf(post.label)===-1){
                    labels.push(post.label)
                }
            });
            dispatch(get_lables(labels));
        })
        .catch(error =>{
            dispatch(get_posts_fail(error));
        });
    }
}

export const getPost = (id) => {
    return dispatch => {
        dispatch(get_post_start());
        axios.get('/posts/' + id + '.json')
        .then(res=>{
            dispatch(get_post_success(res.data, id));
        })
        .catch(error=>{
            dispatch(get_post_fail(error))
        });
    }
}

export const getPostsByLabel = (label) => {
    return dispatch => {
        axios.get('/posts.json')
        .then(res =>{
            let postsbyLabel = [];
            const posts = res.data;
            // eslint-disable-next-line
            Object.keys(posts).map(key => {
                if (posts[key].label === label)
                    postsbyLabel.push({
                        postid: key,
                        postData: posts[key]
                    });
            })
            dispatch(get_posts_success(postsbyLabel));
        })
        .catch(error =>{
            dispatch(get_posts_fail(error));
        });
    }
}
