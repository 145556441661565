import React, {Component} from 'react';
import classes from './navigation.module.css';
// import { Link } from 'react-router-dom';
import * as CONST from '../common/constant';
import {connect} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

class Navigation extends Component {
    state={
        expanded: false
      }
      toggleMenuHandler = () => {
        const showhide = this.state.expanded; 
        this.setState({expanded: !showhide})
      }
    render(){
        let navcontrol = (  
            <Navbar expanded={this.state.expanded} className={classes.colornav} sticky="top" expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={this.toggleMenuHandler} />
                <NavLink to={CONST.HOME_PATH} className={classes.colornavtext} >HOME</NavLink>
                {/* <NavLink to={CONST.NEW_POST_PATH} className={classes.colornavtext} >New Post</NavLink> */}
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        {!this.props.isAuthenticated ? null : <li><NavLink className={classes.colornavtext}  to={CONST.NEW_POST_PATH} onClick={this.toggleMenuHandler}>Post</NavLink></li>}
                        {!this.props.isAuthenticated ? null : <li><NavLink className={classes.colornavtext}  to={CONST.LINK_SCHEDULE} onClick={this.toggleMenuHandler}>Schedule</NavLink></li>}
                        {!this.props.isAuthenticated ? null : <li><NavLink className={classes.colornavtext}  to={CONST.LINK_CUSTOMER} onClick={this.toggleMenuHandler}>Customer</NavLink></li>}
                        {!this.props.isAuthenticated ? null : <li><NavLink className={classes.colornavtext}  to={CONST.LINK_CLEANER} onClick={this.toggleMenuHandler}>Cleaner</NavLink></li>}
                        {!this.props.isAuthenticated ? null : <li><NavLink className={classes.colornavtext}  to={CONST.LINK_ASSIGNMENT} onClick={this.toggleMenuHandler}>Assignment</NavLink></li>}
                    </Nav>
                </Navbar.Collapse>
                {!this.props.isAuthenticated ? <NavLink to={CONST.LOGIN_PATH} className={classes.colornavtext} >LOG IN</NavLink>:<NavLink to={CONST.LOGOUT_PATH} className={classes.colornavtext} >LOG OUT</NavLink>}
            </Navbar>            
        );

        return (
            <div className={classes.header}>
                {navcontrol}
             </div>
        );    
    }
} 
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    }
}
export default connect(mapStateToProps, null)(Navigation); 
