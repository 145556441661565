//For authentication actions
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

//For post actions
export const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS';
export const GET_POSTS_FAIL = 'GET_POSTS_FAIL';
export const GET_POSTS_START = 'GET_POSTS_START';


export const DELETE_POST = 'DELETE_POST';
export const DELETE_START = 'DELETE_START';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAIL = 'DELTE_FAIL';

export const GET_POST = 'GET_POST';
export const GET_POST_START = 'GET_POST_START';
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS';
export const GET_POST_FAIL = 'GET_POST_FAIL';

export const GET_LABELS = 'GET_LABELS';
export const GET_LABELS_SUCCESS = 'GET_LABELS';
export const GET_POSTS_BY_LABEL = 'GET_POSTS_BY_LABEL';

export const GET_ENGLISH_QUIZ_START = 'GET_ENGLISH_QUIZ_START';
export const GET_ENGLISH_QUIZ_SUCCESS = 'GET_ENGLISH_QUIZ_SUCCESS';
export const GET_ENGLISH_QUIZ_FAIL = 'GET_ENGLISH_QUIZ_FAIL';
export const GET_ENGLISH_ANSWERS_START = 'GET_ENGLISH_ANSWERS_START';
export const GET_ENGLISH_ANSWERS_SUCCESS = 'GET_ENGLISH_ANSWERS_SUCCESS';
export const GET_ENGLISH_ANSWERS_FAIL = 'GET_ENGLISH_ANSWERS_FAIL';


//For customer actions
export const GET_CUSTOMERS_START = "GET_CUSTOMERS_START";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL";

export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAIL = "GET_CUSTOMER_FAIL";


export const CREATE_CUSTOMER_START = "CREATE_CUSTOMER_START";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAIL = "CREATE_CUSTOMER_FAIL";

export const UPDATE_CUSTOMER_START = "UPDATE_CUSTOMER_START";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL";

export const DELETE_CUSTOMER_START = "DELETE_CUSTOMER_START";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL";

export const GET_SCHEDULED_CUSTOMERS = 'GET_SCHEDULED_CUSTOMERS';

//For cleaner actions
export const GET_CLEANERS_START = "GET_CLEANERS_START";
export const GET_CLEANERS_SUCCESS = "GET_CLEANERS_SUCCESS";
export const GET_CLEANERS_FAIL = "GET_CLEANERS_FAIL";

export const CREATE_CLEANER_START = "CREATE_CLEANER_START";
export const CREATE_CLEANER_SUCCESS = "CREATE_CLEANER_SUCCESS";
export const CREATE_CLEANER_FAIL = "CREATE_CLEANER_FAIL";

export const UPDATE_CLEANER_START = "UPDATE_CLEANER_START";
export const UPDATE_CLEANER_SUCCESS = "UPDATE_CLEANER_SUCCESS";
export const UPDATE_CLEANER_FAIL = "UPDATE_CLEANER_FAIL";

export const DELETE_CLEANER_START = "DELETE_CLEANER_START";
export const DELETE_CLEANER_SUCCESS = "DELETE_CLEANER_SUCCESS";
export const DELETE_CLEANER_FAIL = "DELETE_CLEANER_FAIL";

//For customer-cleaner actions
export const GET_CUS_CLEANER_START = "GET_CUS_CLEANER_START";
export const GET_CUS_CLEANER_SUCCESS = "GET_CUS_CLEANER_SUCCESS";
export const GET_CUS_CLEANER_FAIL = "GET_CUS_CLEANER_FAIL";

export const SAVE_CUS_CLEANER_START = "SAVE_CUS_CLEANER_START";
export const SAVE_CUS_CLEANER_SUCCESS = "SAVE_CUS_CLEANER_SUCCESS";
export const SAVE_CUS_CLEANER_FAIL = "SAVE_CUS_CLEANER_FAIL";

export const DELETE_CUS_CLEANER_START = "DELETE_CUS_CLEANER_START";
export const DELETE_CUS_CLEANER_SUCCESS = "DELETE_CUS_CLEANER_SUCCESS";
export const DELETE_CUS_CLEANER_FAIL = "DELETE_CUS_CLEANER_FAIL";

export const UPDATE_CUS_CLEANER_START = 'UPDATE_CUS_CLEANER_START';
export const UPDATE_CUS_CLEANER_SUCCESS = 'UPDATE_CUS_CLEANER_SUCCESS';
export const UPDATE_CUS_CLEANER_FAIL = 'UPDATE_CUS_CLEANER_FAIL';