import * as actionTypes from './actionTypes';
import axios from 'axios';

//------------------------------------------------
// GET CLEANERS
//------------------------------------------------
export const get_cleaners_start = () => {
    return {
        type: actionTypes.GET_CLEANERS_START
    }
};

export const get_cleaners_success = (data) => {
    return {
        type: actionTypes.GET_CLEANERS_SUCCESS,
        cleaners: data
    }
};

export const get_cleaners_fail = (error) => {
    return {
        type: actionTypes.GET_CLEANERS_FAIL,
        error: error
    }
};

export const getCleaners = () => {
    return dispatch => {
        dispatch(get_cleaners_start());
        axios.get('/cleaners.json')
            .then(res => {
                dispatch(get_cleaners_success(res.data));
            })
            .catch(error => {
                dispatch(get_cleaners_fail(error));
            })
    }
};

//------------------------------------------------
// CREATE CLEANER
//------------------------------------------------
export const create_cleaner_start = () => {
    return {
        type: actionTypes.CREATE_CLEANER_START
    }
};

export const create_cleaner_success = (data) => {
    return {
        type: actionTypes.CREATE_CLEANER_SUCCESS,
        cleaners: data
    }
};

export const create_cleaner_fail = (error) => {
    return {
        type: actionTypes.CREATE_CLEANER_FAIL,
        error: error
    }
};

export const createCleaner = (data, token) => {
    return dispatch => {
        dispatch(create_cleaner_start());
        axios.post('/cleaners.json?auth=' + token,data)
            .then(() => {
                dispatch(getCleaners());
            })
            .catch(error => {
                dispatch(create_cleaner_fail(error));
            })
    }
};

//------------------------------------------------
// DELETE CLEANERS
//------------------------------------------------
export const delete_cleaner_start = () => {
    return {
        type: actionTypes.DELETE_CLEANER_START
    }
};

export const delete_cleaner_success = (data) => {
    return {
        type: actionTypes.DELETE_CLEANER_SUCCESS,
        cleaners: data
    }
};

export const delete_cleaner_fail = (error) => {
    return {
        type: actionTypes.DELETE_CLEANER_FAIL,
        error: error
    }
};

//------------------------------------------------
// UPDATE CLEANERS
//------------------------------------------------
export const update_cleaner_start = () => {
    return {
        type: actionTypes.UPDATE_CLEANER_START
    }
};

export const update_cleaner_success = (data) => {
    return {
        type: actionTypes.UPDATE_CLEANER_SUCCESS,
        cleaners: data
    }
};

export const update_cleaner_fail = (error) => {
    return {
        type: actionTypes.UPDATE_CLEANER_FAIL,
        error: error
    }
};